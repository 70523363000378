import Vue from 'vue'
import {
  currencyFormatter,
  currentCurrency,
  currentLanguage,
  LANG,
} from '@/language'
import { unitMeasure } from '@/language/unitMeasure'

Vue.prototype.$unitMeasures = unitMeasure
Vue.prototype.$currentLanguage = currentLanguage
Vue.prototype.$currency = currencyFormatter
Vue.prototype.$currentCurrency = currentCurrency

Vue.prototype.$idiomas = [
  { sigla: LANG.PT, nome: 'Português' },
  { sigla: LANG.ES, nome: 'Espanhol' },
  { sigla: LANG.EN, nome: 'Inglês' },
]
