import { isDevMode } from '@/utils/envConfigs'

const featureToggles = Object.entries(process.env).reduce(
  (toggles, [key, value]) => {
    const formattedValue = value === 'true'
    return { ...toggles, [key]: formattedValue }
  },
  {}
)

export default (value) => {
  const key = `VUE_APP_${value.toUpperCase()}`
  const toggleExistsValue = featureToggles[key]

  if (toggleExistsValue === undefined) {
    if (isDevMode) {
      console.error(`Toggle key doesn't exists`, key)
    }
    return false
  }

  return toggleExistsValue
}
