import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import { seasonCrops } from '@/services/__mocks__/seasonCropsSeeds'

const seasonCropsRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/season-crops',
      result: ({ queryParams }) => {
        const filteredSeasonCrops = seasonCrops.filter(
          (farm) => farm.id == queryParams.farm_id
        )
        return APIFailureWrapper({
          content: filteredSeasonCrops,
          errorMessage: 'Erro ao buscar season crops',
        })
      },
    },
    'on'
  ),
]

export { seasonCropsRoutes }
