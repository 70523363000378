<template>
  <v-overlay :value="overlay">
    <v-progress-circular indeterminate size="32" color="green" />
  </v-overlay>
</template>

<script>
export default {
  name: 'LoaderOverlay',

  data: () => ({
    overlay: false,
  }),

  mounted() {
    this.$root.$on('loaderShow', this.show)
    this.$root.$on('loaderHide', this.hide)
  },

  methods: {
    show() {
      this.overlay = true
    },

    hide() {
      this.overlay = false
    },
  },
}
</script>
