import Vue from 'vue'
import OneSignalVue from 'onesignal-vue'
import { isDevMode } from '@/utils/envConfigs'

Vue.use(OneSignalVue)

const initOneSignal = (appId) => {
  if (!isDevMode) {
    Vue.prototype.$OneSignal.init({ appId })
  }
}

export default initOneSignal
