<template>
  <div class="maintenance-page d-flex flex-column mb-6 mt-10">
    <div class="d-flex">
      <v-img
        alt="digifarmz"
        width="152px"
        height="60px"
        contain
        src="img/logos/logo-digifarmz.svg"
      />
    </div>

    <div class="d-flex mt-10">
      <v-img
        alt="maintenance"
        class="d-flex justify-center mt-7"
        width="152px"
        height="82px"
        contain
        src="icons/ic-maintenance.svg"
      />
    </div>

    <div class="d-flex flex-column align-center">
      <div class="maintenance-page__title d-flex mt-9">
        <span>{{ $t('maintenance.title') }}</span>
      </div>

      <div class="maintenance-page__description d-flex mt-6">
        <span v-html="$t('maintenance.description')"></span>
      </div>

      <div class="maintenance-page__button d-flex mt-6">
        <v-btn color="#8bc34a" class="white--text" @click="refresh">
          {{ $t('maintenance.verify_availability') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    refresh() {
      window.location.href = process.env.VUE_APP_URL_PATH
    },
  },
}
</script>

<style scoped>
.maintenance-page {
  height: 100%;
}
.maintenance-page__title {
  font-family: 'Roboto';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 600px;
}
.maintenance-page__description {
  display: block;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 500px;
}

.maintenance-page__button {
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
