export const localeOptions = [
  {
    name: 'Português',
    value: 'pt',
    icon: {
      alt: 'flag-brazil',
      src: require('@/assets/icons/ic-flag-brazil.svg'),
    },
  },
  {
    name: 'English',
    value: 'en',
    icon: {
      alt: 'flag-usa',
      src: require('@/assets/icons/ic-flag-usa.svg'),
    },
  },
  {
    name: 'Español',
    value: 'es',
    icon: {
      alt: 'flag-paraguay',
      src: require('@/assets/icons/ic-flag-paraguay.svg'),
    },
  },
]
