export default {
  my_farm: [
    require('@/assets/img/products/img-my-farm1-768.webp'),
    require('@/assets/img/products/img-my-farm2-768.webp'),
    require('@/assets/img/products/img-my-farm3-768.webp'),
  ],
  plot: [require('@/assets/img/products/img-field-768.webp')],
  planting: [
    require('@/assets/img/products/img-sowing1-768.webp'),
    require('@/assets/img/products/img-sowing2-768.webp'),
    require('@/assets/img/products/img-sowing3-768.webp'),
  ],
  notes: [
    require('@/assets/img/products/img-notes1-768.webp'),
    require('@/assets/img/products/img-notes2-768.webp'),
    require('@/assets/img/products/img-notes3-768.webp'),
  ],
  planning: [
    require('@/assets/img/products/img-planning1-768.webp'),
    require('@/assets/img/products/img-planning2-768.webp'),
  ],
  performance: [require('@/assets/img/products/img-performance-768.webp')],
  catalog: [require('@/assets/img/products/img-plan-catalog-768.webp')],
  mobile_my_farm: [
    require('@/assets/img/products/img-mobile-my-farm1-768.webp'),
    require('@/assets/img/products/img-mobile-my-farm2-768.webp'),
    require('@/assets/img/products/img-mobile-my-farm3-768.webp'),
  ],
  mobile_plot: [require('@/assets/img/products/img-mobile-field-768.webp')],
  mobile_planting: [
    require('@/assets/img/products/img-mobile-sowing1-768.webp'),
    require('@/assets/img/products/img-mobile-sowing2-768.webp'),
    require('@/assets/img/products/img-mobile-sowing3-768.webp'),
  ],
  mobile_notes: [
    require('@/assets/img/products/img-mobile-notes1-768.webp'),
    require('@/assets/img/products/img-mobile-notes2-768.webp'),
    require('@/assets/img/products/img-mobile-notes3-768.webp'),
  ],
  mobile_planning: [
    require('@/assets/img/products/img-mobile-planning1-768.webp'),
    require('@/assets/img/products/img-mobile-planning2-768.webp'),
  ],
  mobile_performance: [
    require('@/assets/img/products/img-mobile-performance-768.webp'),
  ],
  mobile_catalog: [require('@/assets/img/products/img-plan-catalog-768.webp')],
}
