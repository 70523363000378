import store from '@/store'
import cookieHandler from '@/utils/http/cookieHandler'
import i18n from '@/language'
import { MAINTENANCE_MODE, UNAUTHORIZED } from './types/STATUS_CODE'
import { router } from '@/router'
import { EventBus } from '@/utils/eventBus'
import { changePasswordUrl } from '@/modules/auth/services/authService'

export default (error) => {
  const statusCode = error?.response?.status
  if (statusCode === MAINTENANCE_MODE) {
    store.dispatch('apiManager/toggleMaintenanceMode', true)
    return false
  }

  const isRouteLogin = router.history.current.path === '/login'
  const currentApi = error.config.url
  if (statusCode === UNAUTHORIZED && currentApi !== changePasswordUrl) {
    cookieHandler.remove('token')
    store.dispatch('globalStore/resetAllStores')

    if (isRouteLogin) {
      EventBus.$emit('notify-login', i18n.t('exception.invalid_session'))
    } else {
      router.push({ path: '/login' })
    }

    return true
  }

  store.dispatch('apiManager/toggleMaintenanceMode', false)
  return false
}
