import catalog_es from './catalog_es'

export default {
  Catalog: {
    Home: {
      title: 'Catalogar',
      subtitle: 'Planificación',
    },

    Categories: {
      fungicide: 'Fungicida',
      nematicide: 'Nematicida',
      variety: 'Nematicida',
    },
    FilterBar: {
      name: 'Nome del produto',
      order: 'Orden',
      filter: 'Filtrar',
      Crop: {
        soybean: 'Soja',
        wheat: 'Trigo',
      },
    },
    asset_owner: 'Compañia',
    owner_search: 'Buscar nombre de la empresa',
    target: 'Alvo',
    to: 'a',
    chemical_group: 'Grupo químico',
    active_ingredient: 'Ingrediente activo',
    crop_cycle: 'Approximate cycle',
    gmr: 'Grupo de Madurez',
    sowing_time: 'Tiempo de siembra',
    no_selected: 'Seleccione un elemento para ver información detallada',
    general_information: 'Información general',
    action_mode: 'Modo de ación',
    agronomic_category: 'Categoria agronomica',
    operation_type: 'Uso',
    active_ingredient_info: 'Ingrediente activo (concentración)',
    measure: 'Medida',
    formulation: 'Formulación',
    information_variety: 'Información | geolocalizada',
    habit: 'Hábito de crescimiento',
    approximate_cycle: 'Ciclo',
    suggested_population: 'Densidad de siembra',
    ogm: 'OGM - Organismo geneticamente modificado',
    population_suggestion: 'miles plantas/ha',
    dosis_target: 'Dosis por parte de aire objetivo',
    dosis_by_target_values: '{0}L a {1}L',
    Options: {
      Sort: {
        name_a_z: 'Nombre (A - Z)',
        name_z_a: 'Nombre (Z - A)',
        company_a_z: 'Empresa (A - Z)',
        company_z_a: 'Empresa (Z - A)',
        gmr_less_greater: 'Grupo de Madurez (Creciente)',
        gmr_greater_less: 'Grupo de Madurez (Descendiendo)',
      },
      soybean_asian_soybean_rust: 'Roya',
      soybean_anthracnosis: 'Antracnosis',
      soybean_target_spot: 'Mancha Anillada',
      soybean_powdery_mildew: 'Oídio',
      soybean_cercospora_blight: 'Tizón Foliar',
      soybean_septoria_leaf_spot: 'Septoria',
      soybean_aerial_web_blight: 'Mela',
      soybean_white_mold: 'Moho Blanco',
      soybean_frogeye_leaf_spot: 'Mancha ojo de rana',
      soybean_diaporthe: 'Tizón por Diaporthe',
      wheat_leaf_rust: 'Roya',
      wheat_powdery_mildew: 'Oídio',
      wheat_yellow_leaf_spot: 'Mancha Amarilla',
      wheat_fusarium_head_blight: 'Giberela',
      wheat_blast: 'Brusone',
      ...catalog_es,
    },
  },
}
