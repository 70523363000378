const Module = () =>
  import(/* webpackChunkName: "home-module" */ '@/modules/home/Module.vue')
const Home = () =>
  import(/* webpackChunkName: "home-module" */ '@/modules/home/views/Home.vue')

const routes = [
  {
    path: '/my_farm',
    component: Module,
    children: [
      {
        path: '/',
        component: Home,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
