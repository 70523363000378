import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { router } from '@/router'

const sentryEnv = process.env.VUE_APP_SENTRY_ENV

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],
  routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  environment: sentryEnv,
  tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_TRACES,
})
