import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import store from '@/store'
import { privateRoutes, publicRoutes } from '@/router/app'

const currentStore = store

const routes = [...privateRoutes, ...publicRoutes]

const router = new VueRouter({
  routes,
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (err.name === 'NavigationDuplicated') {
      return Promise.resolve(this.currentRoute)
    } else {
      return Promise.reject(err)
    }
  })
}

const originalReplace = router.replace
router.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }

  return originalReplace.call(this, location).catch((err) => {
    if (err.name === 'NavigationDuplicated') {
      return Promise.resolve(this.currentRoute)
    } else {
      return Promise.reject(err)
    }
  })
}

router.beforeEach((to, from, next) => {
  // Get token from other apps, such as Admin Panel
  if (to.query.token) {
    Cookies.set('token', to.query.token, { expires: 1 })

    let query = Object.assign({}, to.query)
    delete query.token
    router.replace({ path: to.path, query })
  }

  // Check if route requires auth
  const routeRequiresAuth = to.matched.find(
    (r) => to?.path == r?.path && r?.meta?.requiresAuth
  )

  if (!Cookies.get('token')) {
    if (to.path == '/login') {
      next()
      return
    }
    next('/login')
    return
  }

  // Check if route requires auth
  if (!routeRequiresAuth && Cookies.get('token')) {
    if (!to.path.match('/reports/')) {
      next('/my_farm')
      return
    }
  }

  // adicionando id da fazenda
  if (to.path == '/my_farm' && !to.query.fazenda_id) {
    if (!from.query.fazenda_id) {
      next()
    } else {
      next({
        path: to.path,
        query: { fazenda_id: from.query.fazenda_id },
      })
    }
  }

  if (to.path === '/plans' && currentStore.getters['user/isPlanLevelFour']) {
    next('/my_farm')
  }

  if (
    currentStore.getters['user/isPlanLevelOne'] ||
    currentStore.getters['user/isFreemiumPlan']
  ) {
    if (
      to.path !== '/plans' &&
      to.path !== '/catalog' &&
      to.path !== '/payment'
    ) {
      next({
        path: '/catalog',
        query: { category: from.query.category || 'fungicide' },
      })
    }
  }

  if (to.path === '/payment' && !currentStore.getters['user/isBrazil']) {
    next({
      path: '/catalog',
      query: { category: 'fungicide' },
    })
  }

  next()
})

export { router }
export default VueRouter
