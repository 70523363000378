import api from '@/services/api'

function initialState() {
  return {
    fazendas: [],
    cultivar: null,
    cultivares: [],
    cultivaresLoading: false,
    sementes: [],
  }
}

export const cultivares = {
  namespaced: true,

  state: {
    initialState: initialState,
    fazendas: initialState().fazendas,
    cultivar: initialState().cultivar,
    cultivares: initialState().cultivares,
    cultivaresLoading: initialState().cultivaresLoading,
    sementes: initialState().sementes,
  },

  getters: {
    fazendas: (state) => {
      return state.fazendas
    },
    cultivares: (state) => {
      return state.cultivares
    },
    cultivaresLoading: (state) => {
      return state.cultivaresLoading
    },
    cultivar: (state) => {
      return state.cultivar
    },
    sementes: (state) => {
      return state.sementes
    },
    varietiesByCrop(state, getters, rootState) {
      const cropId = rootState.crops.currentCrop?.id
      return state.cultivares?.filter((variety) => variety.crop_id == cropId)
    },
  },
  mutations: {
    SET_CULTIVARES(state, cultivares) {
      state.cultivares = cultivares
    },
    SET_CULTIVARES_LOADING(state, loading) {
      state.cultivaresLoading = loading
    },
    SET_CULTIVAR(state, cultivar) {
      state.cultivar = cultivar
    },
    SET_FAZENDAS(state, fazendas) {
      state.fazendas = fazendas
    },
    SET_SEMENTES(state, sementes) {
      state.sementes = sementes
    },
  },

  actions: {
    async listFazendas({ commit }) {
      const response = await api.fazendas.all()

      if (!response) throw new Error('Erro ao buscar fazendas')

      await commit('SET_FAZENDAS', response.data)
      return true
    },
    async excluirFazenda({ state }) {
      const response = await api.fazendas.delete(state.fazenda.id)

      if (!response) throw new Error('Erro ao buscar fazenda')

      return true
    },
    async fetchSeasonCropVarieties({ commit, rootState }, farmId) {
      if (!farmId) return
      const seasonCropId = rootState.seasonsCrops.currentSeasonCrop?.id
      await commit('SET_CULTIVARES_LOADING', true)
      const response = await api.cultivares.get(farmId, seasonCropId)
      await commit('SET_CULTIVARES_LOADING', false)

      if (!response) throw new Error('Erro ao buscar cultivares')

      await commit('SET_CULTIVARES', response.data)
    },
    async excluirCultivar(storage, data) {
      const response = await api.cultivares.delete(data)

      if (!response) throw new Error('Erro ao buscar cultivar')

      return true
    },
    async getSeedsBySelectedCrop({ commit, rootState }, farmId) {
      if (!farmId) return
      const cropId = rootState.crops.currentCrop?.id
      const seasonCrop = rootState.seasonsCrops.currentSeasonCrop

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.sementes.getSeedsWithCultivares(
        farmId,
        cropId,
        seasonCrop?.id
      )
      await commit('SET_SEMENTES', response.data)
    },
    async getNewestSeeds({ commit, rootState }, farmId) {
      const cropId = rootState.crops?.currentCrop?.id

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.sementes.getSeedsWithCultivares(farmId, cropId)

      await commit('SET_SEMENTES', response.data)
    },
    async createVariety(storage, variety) {
      const response = await api.varieties.create(variety)

      return response
    },
    async modalSolicitacao(storage, cultivar) {
      const response = await api.cultivares.solicitacao(
        cultivar.cultivar,
        cultivar.msg
      )

      if (!response) throw new Error('Erro ao enviar solicitação')

      return true
    },
    resetStore({ commit }) {
      commit('SET_CULTIVARES', initialState().fazendas)
      commit('SET_CULTIVARES_LOADING', initialState().cultivar)
      commit('SET_CULTIVAR', initialState().cultivares)
      commit('SET_FAZENDAS', initialState().cultivaresLoading)
      commit('SET_SEMENTES', initialState().sementes)
    },
  },
}
