<template>
  <div>
    <left-drawer
      @set-content="setSubDrawerContent"
      @set-sub-menu-name="setSubDrawerName"
    />
    <sub-drawer :children="subDrawerContent" :name="subDrawerName" />
    <top-bar />
  </div>
</template>

<script>
import LeftDrawer from '@/modules/menu/components/drawers/LeftDrawer.vue'
import SubDrawer from '@/modules/menu/components/drawers/SubDrawer.vue'
import TopBar from '@/modules/menu/components/drawers/TopBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',

  components: {
    LeftDrawer,
    SubDrawer,
    TopBar,
  },

  data() {
    return {
      subDrawerContent: [],
      subDrawerName: '',
    }
  },

  computed: {
    ...mapGetters('layout', ['isSubDrawerOpened']),
    ...mapGetters('user', ['currentUser']),
  },

  methods: {
    setSubDrawerContent(value) {
      this.subDrawerContent = value
    },
    setSubDrawerName(value) {
      this.subDrawerName = value
    },
  },
}
</script>
