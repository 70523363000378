export default {
  login: {
    welcome_back: 'Bienvenido de vuelta',
    welcome_message: 'Ingrese para acceder a la plataforma',
    email: 'Email',
    send_email: 'Enviar email',
    digit_email: 'Ingrese su email',
    password: 'Contraseña',
    digit_password: 'Ingrese su contraseña',
    forgot_password: 'Olvidé mi contraseña',
    back_to_login: 'Atrás para iniciar sesión',
    recover_password: 'Recuperar contraseña',
    platform_access: 'Acceder a la plataforma',
    need_help: '¿Necesitas ayuda?',
    contact_us: 'Contáctenos',
    select_account: 'Seleccione una cuenta',
    check_email: 'Verifique su email',
    check_email_text:
      'Allí tendrás instrucciones para </br>redefinición de tu contraseña',
  },
}
