<template>
  <v-app :style="{ background: menuThemeBackground }">
    <maintenance v-if="isMaintenanceMode" />
    <router-view v-else />
  </v-app>
</template>

<script>
import Maintenance from '@/pages/maintenance'
import { mapGetters } from 'vuex'

export default {
  name: 'MainApp',

  components: {
    Maintenance,
  },

  computed: {
    ...mapGetters('apiManager', ['isMaintenanceMode']),
    menuThemeBackground() {
      const grey = '#F8F9F8'
      return grey
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Rubik:wght@300;400;500;600;700&family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap');
</style>
