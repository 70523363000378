<template>
  <v-card class="card" outlined>
    <div class="df-flex-l df-flex-l-m align-center">
      <div class="df-col-4 df-col-12-m card__title">
        <h1>{{ $t('card_info_plan.title_main') }}</h1>
        <p>{{ $t('card_info_plan.title_description') }}</p>
      </div>
      <div class="df-col-4 df-col-12-m card__subtitle">
        <h2>{{ $t('card_info_plan.subtitle_main') }}</h2>
        <div v-if="!!cheapestPrice" class="price">
          <p>
            {{ $t('card_info_plan.money_unit') }}
            <span class="price__value">{{ formatNumber(cheapestPrice) }}</span>
            {{ $t('card_info_plan.per_month') }}
          </p>
        </div>
      </div>
      <df-button
        class="df-col-4 df-col-12-m"
        :flank="'fa-pull-right pl-1 fa-1x'"
        :icon="'arrow-right'"
        @click="togglePlans"
      >
        {{ $t('card_info_plan.button') }}
      </df-button>
    </div>
  </v-card>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PlanMixin from '@/components/PlanMixin.vue'
import { logEvent, events } from '@/services/analytics'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CardInfoPlan',

  mixins: [PlanMixin],

  components: { DfButton },

  watch: {
    currentLanguage: {
      handler(language) {
        this.setPlans(language)
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('plans', ['cheapestPrice']),
    currentLanguage() {
      return this.$currentLanguage()
    },
  },

  methods: {
    ...mapActions('plans', ['setPlans']),
    formatNumber(value) {
      if (value && ['pt', 'es'].includes(this.currentLanguage)) {
        return value.replace('.', ',')
      } else {
        return value
      }
    },
    togglePlans() {
      logEvent(events.plansModule.clicked_see_plans)
      this.$router.replace({
        path: '/plans',
      })
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #f8f9f8;
  padding: 12px 24px;

  .card__title {
    h1 {
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      color: #071505;
      margin-bottom: 2px;
    }

    p {
      font-family: 'Rubik';
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #3f4e3c;
    }
  }
  .card__subtitle {
    h2 {
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 10px;
      line-height: 18px;
      color: #aab2a9;
      text-transform: uppercase;
    }
    .price {
      height: 44px;
      width: 132px;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 8px 10px;

      @include d(m) {
        width: 100%;
      }
      p {
        font-family: 'Rubik';
        font-weight: 600;
        font-size: 9px;
        line-height: 10px;
        color: #aab2a9;

        .price__value {
          font-family: 'Rubik';
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #39af49;
          margin-left: 1px;
        }
      }
    }
  }
}
</style>
