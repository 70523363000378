import Vue from 'vue'
import MainApp from '@/MainApp.vue'
import i18n from '@/language'
import VueRouter, { router } from '@/router'
import { isDevMode } from '@/utils/envConfigs'
import store from '@/store'
import api from '@/services/api'
import featureToggle from '@/utils/featureToggle'
import permissionMap from '@/modules/auth/helper/permissionsMap.js'
import '@/utils/directives'
import loadPrototypes, { errorsPrototypes } from '@/utils/prototypes'

// plugins
import '@/plugins/fontawesome.js'
import '@/plugins/jsts.js'
import '@/plugins/i18n.js'
import '@/plugins/leaflet.js'
import '@/plugins/money.js'
import '@/plugins/moment.js'
import '@/plugins/sentry.js'
import '@/plugins/vue-google-maps.js'
import '@/plugins/vue-mask.js'
import '@/plugins/vue-tour.js'
import '@/plugins/zendesk.js'
import initOneSignal from '@/plugins/onesignal.js'
import vuetify from '@/plugins/vuetify/vuetify.js'
import { setupMirage } from '@/plugins/mirage.js'

Vue.use(VueRouter)
setupMirage()
loadPrototypes()

Vue.prototype.$api = api
Vue.prototype.$toggle = featureToggle
Vue.prototype.$PermissionError = errorsPrototypes.permissionError
Vue.prototype.$p = permissionMap

Vue.config.productionTip = false
Vue.config.errorHandler = (err) => {
  if (isDevMode) {
    console.error('You should not ignore it: ', err)
  }
}
if (isDevMode) {
  Vue.config.devtools = true
}

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(MainApp),
  beforeMount() {
    if (!isDevMode) {
      initOneSignal(process.env.VUE_APP_ONESIGNAL_APP_ID)
    }
  },
}).$mount('#app')
