import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

if (
  process.env.VUE_APP_ZENDESK_ENABLE == 'true' &&
  process.env.VUE_APP_ZENDESK_ID
) {
  Vue.use(Zendesk, {
    key: process.env.VUE_APP_ZENDESK_ID,
    disabled: false,
    hideOnLoad: false,
    settings: {
      webWidget: {
        color: {
          theme: '#78a300',
        },
      },
    },
  })
}
