import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/language'
import preset from '@/plugins/vuetify/preset.js'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  preset,
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
})

export default vuetify
