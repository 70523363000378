function initialState() {
  return {
    cheapestPrice: null,
    plans: [],
    englishPlans: [],
    spanishPlans: [],
    portuguesePlans: [],
  }
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    cheapestPrice: initialState().cheapestPrice,
    plans: initialState().plans,
    englishPlans: initialState().englishPlans,
    spanishPlans: initialState().spanishPlans,
    portuguesePlans: initialState().portuguesePlans,
  },

  getters: {
    cheapestPrice(state) {
      return state.cheapestPrice
    },
    plans(state) {
      return state.plans
    },
  },

  mutations: {
    SET_CHEAPEST_PRICE(state) {
      if (!state.plans.length) {
        state.cheapestPrice = null
        return
      }
      const cheapestPlanPrice = Math.min(
        ...state.plans.map((plan) => {
          return plan.price.month
        })
      )
      const formattedCheapestPlanPrice = cheapestPlanPrice.toFixed(2)
      state.cheapestPrice = formattedCheapestPlanPrice
    },
    SET_PLANS(state, value) {
      state.plans = value
    },
    SET_TRANSLATED_PLANS(state, payload) {
      if (payload.language === 'pt') state.portuguesePlans = payload.plans
      else if (payload.language === 'en') state.englishPlans = payload.plans
      else if (payload.language === 'es') state.spanishPlans = payload.plans
      else {
        state.portuguesePlans = []
        state.englishPlans = []
        state.spanishPlans = []
      }
    },
  },

  actions: {
    async setPlans({ commit, state }, language) {
      const translations = {
        en: state.englishPlans,
        es: state.spanishPlans,
        pt: state.portuguesePlans,
      }
      if (translations[language].length) {
        commit('SET_PLANS', translations[language])
      } else {
        let plans
        try {
          const languagePlan = await import(
            `@/modules/plans/data/plans${
              language.charAt(0).toUpperCase() + language.slice(1)
            }.js`
          )
          plans = languagePlan.default
          commit('SET_PLANS', plans)
          commit('SET_TRANSLATED_PLANS', { language, plans })
        } catch (error) {
          throw new Error(error)
        }
      }
      commit('SET_CHEAPEST_PRICE')
    },
    resetStore({ commit }) {
      commit('SET_PLANS', initialState().plans)
      commit('SET_TRANSLATED_PLANS', {})
      commit('SET_CHEAPEST_PRICE')
    },
  },
}
