import Vue from 'vue'

import { Coordinate, GeometryFactory } from 'jsts/org/locationtech/jts/geom.js'
import { GeometryGraph } from 'jsts/org/locationtech/jts/geomgraph.js'
import { valid } from 'jsts/org/locationtech/jts/operation.js'

Vue.prototype.$jsts = {
  Coordinate: Coordinate,
  GeometryFactory: GeometryFactory,
  GeometryGraph: GeometryGraph,
  valid: valid,
}
