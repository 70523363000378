export default {
  Modal: {
    delete: {
      cant_undo_message: "This action can't be undone",
      field_delete_confirmation: 'Are you sure you want to delete this field?',
      sowing_delete_confirmation:
        'Are you sure you want to delete this sowing?',
    },
    standard: {
      cancel: 'Cancel',
    },
  },
}
