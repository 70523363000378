<template>
  <div>
    <v-menu content-class="elevation-0 avatar-menu" rounded="rounded">
      <template #activator="{ on, attrs }">
        <div class="d-flex" v-bind="attrs" v-on="on">
          <v-avatar class="ml-2 mr-1" color="#788476" size="36">
            <v-icon dark> mdi-account-circle </v-icon>
          </v-avatar>

          <v-icon dark color="#788476"> mdi-chevron-down </v-icon>
        </div>
      </template>
      <v-list>
        <v-subheader class="text-subheader">
          {{ accountName }}
        </v-subheader>

        <v-menu bottom left offset-x v-if="contas">
          <template #activator="{ on }">
            <v-list-item link v-on="on" class="color-menu-avatar">
              <v-list-item-icon class="mr-4">
                <font-awesome-icon class="icon-outlined mr-2" icon="sync" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Menu.alterar_conta') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              v-for="(conta, id) in contas"
              :key="id"
              class="color-menu-avatar"
              @click="alterarConta(id, conta)"
            >
              <v-list-item-title class="body-2">
                {{ conta }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item v-if="hasPermission" to="/users" class="color-menu-avatar">
          <v-list-item-icon class="mr-4">
            <font-awesome-icon class="icon-outlined mr-2" icon="users" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('Menu.gerenciar_usuarios')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader class="text-subheader uppercased">
          {{ $t('Menu.about_the_user') }}
        </v-subheader>

        <v-list-item link class="color-menu-avatar" @click="openModalSenha">
          <v-list-item-icon class="mr-4">
            <font-awesome-icon class="icon-outlined mr-2" icon="key" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('Menu.trocar_senha') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-menu bottom left offset-x>
          <template #activator="{ on }">
            <v-list-item
              link
              v-on="on"
              class="color-menu-avatar"
              data-v-step="user-menu"
            >
              <v-list-item-icon class="mr-4">
                <font-awesome-icon class="icon-outlined mr-2" icon="language" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Menu.alterar_idioma') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <div v-for="(idioma, index) in $idiomas" :key="index">
              <v-list-item
                :class="['color-menu-avatar']"
                @click="changeLanguage(idioma.sigla)"
              >
                <v-list-item-title class="body-2">
                  {{ $t('language.' + idioma.sigla) }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
    <modal-senha :dialog="dialog" @close="closeModalSenha" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalSenha from '@/modules/menu/components/modals/ModalSenha.vue'

export default {
  name: 'AccountConfigs',

  props: {
    hasPermission: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    ModalSenha,
  },

  data() {
    return {
      contas: [],
      dialog: false,
    }
  },

  async mounted() {
    let response
    try {
      response = await this.$api.menu.contas()
    } catch (error) {
      console.error(error)
    }
    this.contas = response.data.contas
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    accountName() {
      return this.currentUser?.account?.name || this.$t('report.account')
    },
  },

  methods: {
    async alterarConta(accountId) {
      this.$root.$emit('loaderShow')
      try {
        await this.$api.menu.alterarConta(accountId)
        // FIX-ME: This breaks the SPA intention. It should not reload the whole app when changing the account
        window.location.href = process.env.VUE_APP_BASE_URL
      } catch (e) {
        this.$root.$emit('loaderHide')
      }
    },
    async changeLanguage(language) {
      this.$root.$i18n.locale = language
      localStorage.setItem('language', language)
    },
    closeModalSenha() {
      this.dialog = false
    },
    openModalSenha() {
      this.dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.color-menu-avatar {
  color: #788476 !important;
}
.avatar-menu {
  top: 60px !important;
  border: 1px solid #e6e9e6 !important;
}
.text-subheader {
  color: #aab2a9;

  .uppercased {
    text-transform: uppercase;
  }
}
</style>
