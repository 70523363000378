function initialState() {
  return {
    menu: {
      drawer: false,
      isSubDrawerOpened: false,
      openedSubDrawerName: null,
    },
  }
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    menu: initialState().menu,
  },

  getters: {
    drawer(state) {
      return state.menu.drawer
    },
    isSubDrawerOpened(state) {
      return state.menu.isSubDrawerOpened
    },
    openedSubDrawerName(state) {
      return state.menu.openedSubDrawerName
    },
  },

  mutations: {
    SET_DRAWER(state, payload) {
      state.menu.drawer = payload
    },
    SET_SUBDRAWER(state, payload) {
      state.menu.isSubDrawerOpened = payload
    },
    SET_OPENED_SUBDRAWER_NAME(state, payload) {
      state.menu.openedSubDrawerName = payload
    },
  },

  actions: {
    setDrawer({ commit }, payload) {
      commit('SET_DRAWER', payload)
    },
    setSubDrawer({ commit }, payload) {
      commit('SET_SUBDRAWER', payload)
      if (!payload) {
        commit('SET_OPENED_SUBDRAWER_NAME', null)
      }
    },
    openDrawerAndSubDrawer({ commit }, payload) {
      commit('SET_DRAWER', true)
      commit('SET_SUBDRAWER', true)
      commit('SET_OPENED_SUBDRAWER_NAME', payload)
    },
    closeDrawerAndSubDrawer({ commit, state }) {
      if (state.menu.isSubDrawerOpened) {
        commit('SET_SUBDRAWER', false)
        setTimeout(() => {
          commit('SET_DRAWER', false)
        }, 300)
      } else {
        commit('SET_DRAWER', false)
      }
      commit('SET_OPENED_SUBDRAWER_NAME', null)
    },
    resetStore({ commit }) {
      commit('SET_DRAWER', initialState().menu.drawer)
      commit('SET_SUBDRAWER', initialState().menu.isSubDrawerOpened)
      commit(
        'SET_OPENED_SUBDRAWER_NAME',
        initialState().menu.openedSubDrawerName
      )
    },
  },
}
