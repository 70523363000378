<template>
  <v-card id="helpButton">
    <v-speed-dial
      v-model="fab"
      bottom="bottom"
      right="right"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn v-model="fab" width="48" height="48" color="#39AF49" dark>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-help </v-icon>
        </v-btn>
      </template>
      <v-card class="top-content">
        <v-btn class="group-btn-top" disabled>
          <v-icon color="#aab2a9" class="pr-2"> mdi-play </v-icon>
          <span class="pr-3">{{ $t('help_button.tour') }}</span>
          <v-card class="shortly">
            <span style="color: #4a76bc">{{ $t('help_button.shortly') }}</span>
          </v-card>
        </v-btn>
        <v-btn
          class="group-btn-bottom"
          :href="$t('help_button.contact.link')"
          target="_blank"
          v-if="showContactService"
        >
          <v-icon color="#aab2a9" class="pr-2"> mdi-phone </v-icon>
          <span>{{ $t('help_button.contact.title') }}</span>
        </v-btn>
      </v-card>
    </v-speed-dial>
  </v-card>
</template>

<script>
import { LANG } from '@/language'

export default {
  name: 'HelpButton',

  data() {
    return {
      fab: false,
    }
  },

  computed: {
    showContactService() {
      return localStorage.getItem('language') !== LANG.EN
    },
  },
}
</script>

<style lang="scss" scoped>
#helpButton {
  bottom: 16px;
}
#helpButton .v-size--default {
  min-width: 48px;
}
#helpButton .v-speed-dial {
  position: fixed;
  bottom: 16px;
}
#helpButton .v-btn--floating {
  position: relative;
}
#helpButton .v-speed-dial__list {
  display: grid;
  justify-content: end;
}
#helpButton .v-btn--disabled {
  background-color: #ffffff !important;
}
.group-btn-top {
  justify-content: flex-start;
  margin: 0 !important;
  border-radius: 4px 4px 0 0;
  width: 100%;
  background-color: #ffffff !important;
  text-transform: none;
}
.group-btn-bottom {
  justify-content: flex-start;
  margin: 0 !important;
  border-radius: 0 0 4px 4px;
  width: 100%;
  background-color: #ffffff !important;
  text-transform: none;
}
.shortly {
  height: 20px;
  width: 64px;
  font-size: x-small;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4a76bc !important;
}
.top-content {
  position: fixed;
  right: 16px;
  bottom: 72px;
  max-width: 290px !important;
}
</style>
