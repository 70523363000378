function initialState() {
  return {
    permissions: [],
  }
}

import api from '@/services/api'

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    permissions: initialState().permissions,
  },

  getters: {
    hasPermissions(state) {
      return state.permissions?.length > 0
    },
    hasPermission: (state) => (permissionToCheck) => {
      const permissionFound = state.permissions.find(
        (permission) => permission == permissionToCheck
      )
      return Boolean(permissionFound)
    },
    permissions(state) {
      return state.permissions
    },
  },

  mutations: {
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
  },

  actions: {
    async fetchPermissions({ commit, rootState }) {
      try {
        const seasonCropId = rootState.seasonsCrops.currentSeasonCrop?.id
        const { data } = await api.permissions.get(seasonCropId)
        commit('SET_PERMISSIONS', data)
      } catch ($e) {
        throw new Error('Erro ao buscar permissão')
      }
    },
    resetStore({ commit }) {
      commit('SET_PERMISSIONS', initialState().permissions)
    },
  },
}
