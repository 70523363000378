function initialState() {
  return {
    maintenanceMode: false,
    apiError: null,
  }
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    maintenanceMode: initialState().maintenanceMode,
    apiError: initialState().apiError,
  },

  getters: {
    getApiError(state) {
      return state.apiError
    },
    isMaintenanceMode(state) {
      return state.maintenanceMode
    },
  },

  mutations: {
    SET_API_ERROR(state, apiError) {
      state.apiError = apiError
    },
    TOGGLE_MAINTENANCE_MODE(state, shouldEnable) {
      state.maintenanceMode = shouldEnable
    },
  },

  actions: {
    toggleMaintenanceMode({ commit }, shouldEnable) {
      commit('TOGGLE_MAINTENANCE_MODE', shouldEnable)
    },
    setApiError({ commit }, err) {
      const formattedError = {
        code: err?.code,
        key: err?.key ?? 'exception.unexpected_error',
        context: err?.context,
      }
      commit('SET_API_ERROR', formattedError)
    },
    resetApiErrorMessage({ commit }) {
      commit('SET_API_ERROR', initialState().apiError)
    },
    resetStore({ commit }) {
      commit('SET_API_ERROR', initialState().apiError)
      commit('TOGGLE_MAINTENANCE_MODE', initialState().maintenanceMode)
    },
  },
}
