import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { currentLanguage } from '@/language'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places,drawing,geometry',
    language: currentLanguage(),
  },
})
