import api from '@/services/api'

function initialState() {
  return {
    integrations: {
      fieldview: null,
    },
  }
}

export const integrations = {
  namespaced: true,

  state: () => ({
    initialState: initialState,
    integrations: initialState().integrations,
  }),

  getters: {
    integrations: (state) => {
      return state.integrations
    },
    isIntegrated: (state) => {
      return state.integrations?.fieldview?.integrated ? true : false
    },
  },

  mutations: {
    SET_INTEGRATIONS(state, { fieldview }) {
      state.integrations.fieldview = fieldview ?? null
    },
  },

  actions: {
    async fetchIntegrations({ commit }) {
      const { data } = await api.integrations.fetchIntegrations()

      if (!data) throw new Error('Fail to fetch integrations')

      if (data.fieldview) {
        await commit('SET_INTEGRATIONS', data)
      } else {
        await commit('SET_INTEGRATIONS', { fieldview: null })
      }
    },
    async disableFieldview({ dispatch }) {
      await api.integrations.disableFieldview()
      await dispatch('fetchIntegrations')
    },
    resetStore({ commit }) {
      commit('SET_INTEGRATIONS', initialState().integrations)
    },
  },
}
