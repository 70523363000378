export default {
  Modal: {
    delete: {
      cant_undo_message: 'Essa ação não poderá ser desfeita',
      field_delete_confirmation: 'Tem certeza que deseja excluir esse talhão?',
      sowing_delete_confirmation:
        'Tem certeza que deseja excluir essa semeadura?',
    },
    standard: {
      cancel: 'Cancelar',
    },
  },
}
