import storage from '@/services/storage/storage'

function CropStorage(id) {
  this.storageKey = 'current_crop'
  this.cropId = id

  this.id = function (id) {
    this.cropId = id
    return this
  }

  this.get = function () {
    const storedCurrentCrop = storage(this.storageKey).recover()

    return storedCurrentCrop || ''
  }

  this.clear = function () {
    storage(this.storageKey).clear()
  }

  this.save = function () {
    return storage(this.storageKey).save(this.cropId)
  }
}

export default (id) => new CropStorage(id)
