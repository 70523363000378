<script>
import LayoutMixin from '@/components/LayoutMixin'

export default {
  mixins: [LayoutMixin],

  data() {
    return {
      crumbs: [],
    }
  },

  mounted() {
    this.updateBreadcrumbs()
  },

  watch: {
    $route() {
      this.updateBreadcrumbs()
    },
    currentLanguage() {
      this.updateBreadcrumbs()
    },
  },

  computed: {
    currentLanguage() {
      return this.$currentLanguage()
    },
  },

  methods: {
    updateBreadcrumbs() {
      const pathsUrl = [
        {
          to: 'my_farm',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm', disabled: true },
          ],
        },
        {
          to: 'cultivares',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.planejamento') },
            {
              text: this.$t('menu.cultivares'),
              disabled: true,
            },
          ],
        },
        {
          to: 'programas',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.planejamento') },
            {
              text: this.$t('menu.cultivares'),
              to: `cultivares?fazenda_id=${this.$route.query.fazenda_id}`,
            },
            { text: this.$t('menu.programas'), disabled: true },
          ],
        },
        {
          to: 'comparacao',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.planejamento') },
            {
              text: this.$t('menu.cultivares'),
              to: `cultivares?fazenda_id=${this.$route.query.fazenda_id}`,
            },
            {
              text: this.$t('menu.programas'),
              to: `programas?cultivar_id=${this.$route.query.cultivar_id}&fazenda_id=${this.$route.query.fazenda_id}`,
            },
            {
              text: this.$t('menu.comparison'),
              disabled: true,
            },
          ],
        },
        {
          to: 'atividades',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.semeaduras'), disabled: true },
          ],
        },
        {
          to: 'catalog?category=fungicide',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.catalog') },
            { text: this.$t('menu.fungicides'), disabled: true },
          ],
        },
        {
          to: 'catalog?category=nematicide',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            {
              text: this.$t('menu.catalog'),
              to: 'catalog?category=fungicide',
              disabled: false,
            },
            { text: this.$t('menu.nematicide'), disabled: true },
          ],
        },
        {
          to: 'catalog?category=variety',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            {
              text: this.$t('menu.catalog'),
              to: 'catalog?category=fungicide',
              disabled: false,
            },
            { text: this.$t('disease_control.varieties'), disabled: true },
          ],
        },
        {
          to: 'notes',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.notes'), disabled: true },
          ],
        },
        {
          to: 'performance',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.performance'), disabled: true },
          ],
        },
        {
          to: 'plot',
          full: [
            { text: this.$t('menu.my_farm'), to: 'my_farm' },
            { text: this.$t('menu.plot'), disabled: true },
          ],
        },
      ]

      const currentPath = this.$route.fullPath
      const path = pathsUrl.find((p) => currentPath.includes(p.to)) || {
        full: [],
      }

      this.crumbs = path.full
    },
  },
}
</script>

<template>
  <v-breadcrumbs :items="crumbs">
    <template #divider>
      <font-awesome-icon icon="chevron-right" class="divider-icon" />
    </template>
    <template #item="{ item }">
      <v-breadcrumbs-item
        v-if="item.to == 'my_farm'"
        :to="item.to"
        :disabled="item.disabled"
      >
        <font-awesome-icon icon="home" class="breadcrumbs-icon" />
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        v-else
        active-class="breadcrumbs-item__active"
        class="breadcrumbs-item"
        exact
        :to="item.to"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<style lang="scss" scoped>
.divider-icon {
  font-size: 10px;
}

.breadcrumbs-icon {
  height: 19px;
  width: 21px;
  color: #788476;
}

.breadcrumbs-item {
  color: #788476;
  font-weight: 400;
  font-family: 'Rubik';
  font-size: 14px;
  line-height: 21px;
}
</style>

<style lang="scss">
.breadcrumbs-item__active {
  color: #1a2b46;
}

.breadcrumbs-item a {
  color: #788476 !important;
  font-weight: 400;
  font-family: 'Rubik';
  font-size: 14px;
  line-height: 21px;

  &:hover {
    transform: scale(1.05);
    text-decoration: underline;
  }
}
</style>
