function initialState() {
  return {
    hasWhiteMold: false,
  }
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    hasWhiteMold: initialState().hasWhiteMold,
  },

  getters: {
    hasProgramWithWhiteMold(state) {
      return state.hasWhiteMold
    },
  },

  mutations: {
    SET_HAS_WHITE_MOLD(state, value) {
      state.hasWhiteMold = value
    },
  },

  actions: {
    verifyProgramsWithWhiteMold({ commit }, programs) {
      const programWhiteMold = programs.find(
        (program) => program.white_mold_enabled
      )

      commit('SET_HAS_WHITE_MOLD', Boolean(programWhiteMold))
    },
    verifyProgramHasWhiteMold({ commit }, program) {
      commit('SET_HAS_WHITE_MOLD', program?.white_mold_enabled)
    },
    resetStore({ commit }) {
      commit('SET_HAS_WHITE_MOLD', initialState().hasWhiteMold)
    },
  },
}
