import Vuex from 'vuex'
import Vue from 'vue'

import { semeaduras } from '@/store/semeaduras'
import { cultivares } from '@/store/cultivares'
import { defensives } from '@/store/defensives'
import { execucoes } from '@/store/execucoes'
import { crops } from '@/store/crops'
import { integrations } from '@/store/integrations'
import { seasonsCrops } from '@/store/seasonsCrops'
import { globalStore } from '@/store/globalStore'
import user from '@/store/user'
import farms from '@/store/farms'
import program from '@/store/program'
import apiManager from '@/store/apiManager'
import catalog from '@/modules/catalog/store'
import layout from '@/modules/menu/store/layout'
import plans from '@/modules/plans/store/planStore'
import plot from '@/modules/plot/store/plotStore'
import permissions from '@/modules/auth/store/permissionStore'
import notes from '@/modules/notes/store/noteStore'
import freemium from '@/store/freemium'

Vue.use(Vuex)

export const modules = {
  semeaduras,
  cultivares,
  defensives,
  execucoes,
  crops,
  integrations,
  user,
  farms,
  apiManager,
  seasonsCrops,
  program,
  catalog,
  layout,
  plans,
  permissions,
  plot,
  notes,
  freemium,
  globalStore,
}

export default new Vuex.Store({
  modules,
})
