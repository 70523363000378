export default {
  Note: {
    new_note: 'New annotation',
    edit: 'Edit annotation',
    filter_note: 'Filter annotation',
    notes: 'Notes',
    field_notebook: 'Field notebook',
    note_category: 'Annotation category',
    note_plot: 'Associate to field',
    select_plot: 'Select the filed',
    register_success: 'Annotation registered successfully',
    delete_confirm:
      'Are you sure you want to delete this annotation? You will not be able to restore it later.',
    delete_success: 'Anotación eliminada con éxito',
    category: {
      buy_and_sell: 'Buy and sell',
      climate: 'Climate',
      diseases: 'Diseases',
      fertilizing: 'Fertilizing',
      machines: 'Machines',
      nematodes: 'Nematodes',
      others: 'Others',
      pests: 'Pests',
    },
    loading: {
      erro: 'Error loading notes',
    },
    no_notes: "You still don't have any notes",
    add_first: 'Register your first field note',
    add_first_msg:
      'Start recording the day to day of your farming digitally, so you will have a history of everything that happens.',
  },
}
