import api from '@/services/api'
import cropStorage from '@/services/storage/cropStorage'
import { seasonsCrops } from '@/store/seasonsCrops'
import { CROP } from '@/utils/crops'

function initialState() {
  return {
    crops: [],
    currentCrop: null,
  }
}

export const crops = {
  namespaced: true,

  state: {
    initialState: initialState,
    crops: initialState().crops,
    currentCrop: initialState().currentCrop,
  },

  getters: {
    maxSprayingsByCurrentCrop: (state) => {
      const currentCropId = state?.currentCrop?.id
      if (currentCropId == CROP.SOYBEAN) {
        return process.env.VUE_APP_MAX_APLICACOES
      }
      if (currentCropId == CROP.WHEAT) {
        return process.env.VUE_APP_WHEAT_MAX_SPRAYINGS
      }
    },
    currentCrop: (state) => {
      return state.currentCrop
    },
    currentCropId: (state) => {
      return state.currentCrop?.id
    },
    isSoybean: (state) => {
      return state.currentCrop?.id == 1 ?? false
    },
    isWheat: (state) => {
      return state.currentCrop?.id == 2 ?? false
    },
  },

  mutations: {
    SET_CROPS(state, crops) {
      state.crops = crops
    },
    SET_CURRENT_CROP_ID(state, cropId) {
      const currentCrop = state.crops.find((crop) => crop.id == cropId)

      if (!currentCrop) throw new Error('Invalid selected crop')

      state.currentCrop = currentCrop
      cropStorage(cropId).save()
    },
    RESET_CURRENT_CROP(state) {
      state.currentCrop = initialState().currentCrop
    },
  },

  actions: {
    async setCurrentCrop({ commit }, cropId) {
      commit('SET_CURRENT_CROP_ID', cropId)
    },
    async fetchCrops({ commit }) {
      try {
        const { data } = await api.crops.all()
        commit('SET_CROPS', data)
      } catch ($e) {
        throw new Error('Erro ao buscar crops')
      }
    },
    loadCurrentCropByStorage({ commit, state }) {
      const cropFromStorage = cropStorage().get()

      const cropExistsInAccount =
        state.crops.find((crop) => crop?.id == cropFromStorage) ?? false

      if (cropFromStorage && cropExistsInAccount) {
        commit('SET_CURRENT_CROP_ID', cropFromStorage)
        return
      }

      commit(
        'SET_CURRENT_CROP_ID',
        seasonsCrops.state?.seasonsCrops[0]?.crop_id
      )
    },
    resetStore({ commit }) {
      commit('SET_CROPS', initialState().crops)
      commit('RESET_CURRENT_CROP')
      cropStorage().clear()
    },
  },
}
