function initialState() {
  return {
    plots: [],
    loadingPlot: false,
  }
}

import api from '@/services/api'

const notifyAlert = (message) => {
  const event = new CustomEvent('notify', {
    bubbles: true,
    detail: {
      color: 'error',
      text: message,
    },
  })
  document.dispatchEvent(event)
}
const requestPlots = async (commit, farmId, params = null) => {
  let response = []
  commit('SET_LOADING', true)
  try {
    if (farmId) {
      const { data } = await api.talhoes.get(farmId, params)
      response = data
      commit('SET_PLOTS', data)
    }
  } catch (error) {
    console.error(error)
    notifyAlert('Plot.Exceptions.get_plots')
  } finally {
    commit('SET_LOADING', false)
  }
  return response
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    plots: initialState().plots,
    loadingPlot: initialState().loadingPlot,
  },

  getters: {
    plots: (state) => {
      return state.plots
    },
    loadingPlot: (state) => {
      return state.loadingPlot
    },
  },

  mutations: {
    SET_PLOTS(state, plots) {
      state.plots = plots
    },
    SET_LOADING(state, value) {
      state.loadingPlot = value
    },
  },

  actions: {
    async createPlot({ commit }, plot) {
      try {
        await api.talhoes.create(plot)
      } catch (error) {
        console.error(error)
        notifyAlert('Plot.Exceptions.create_plot')
      }
      await requestPlots(commit, plot.fazenda_id)
    },
    async createPlotByKMLFile({ commit }, data) {
      try {
        await api.talhoes.createAll(data)
      } catch (error) {
        if (error.response && error.response.data) {
          notifyAlert(this.$t(error.response.data.key))
        }
        throw error
      }
      const farmId = data.plots[0].fazenda_id
      await requestPlots(commit, farmId)
    },
    async updatePlot({ commit }, plot) {
      try {
        await api.talhoes.update(plot)
      } catch (error) {
        console.error(error)
        notifyAlert('Plot.Exceptions.update_plot')
      }
      await requestPlots(commit, plot.fazenda_id)
    },
    async getPlots({ commit }, { farmId, params = null }) {
      const plots = await requestPlots(commit, farmId, params)
      return plots
    },
    async deletePlot({ commit, state }, plotId) {
      try {
        await api.talhoes.delete(plotId)
        const plots = state.plots.filter((plot) => plot.id !== plotId)
        await commit('SET_PLOTS', plots)
      } catch (error) {
        if (error.response && error.response.data)
          notifyAlert(`${error.response.data.key}`)
      }
    },
    async enableOrDisablePlot({ commit, state }, plotId) {
      try {
        await api.talhoes.enableDisable(plotId)
        const plots = state.plots
        const plot = plots.find((plot) => plot.id === plotId)
        plot.enabled = !plot.enabled

        await commit('SET_PLOTS', plots)
      } catch (error) {
        console.error(error)
      }
    },
    resetStore({ commit }) {
      commit('SET_PLOTS', initialState().plots)
      commit('SET_LOADING', initialState().loadingPlot)
    },
  },
}
