export const globalStore = {
  namespaced: true,

  actions: {
    resetAllStores({ dispatch, rootState }) {
      for (const storeName in rootState) {
        const currentStore = rootState[storeName]
        if (Object.keys(currentStore).includes('initialState')) {
          dispatch(`${storeName}/resetStore`, null, { root: true })
        }
      }
    },
  },
}
